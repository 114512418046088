import React from 'react';
import PropTypes from 'prop-types';
import {GridLoader} from 'react-spinners';
import './styles.scss';

const Loader = ({ className, loading, size = 35, backdrop, ...rest }) => {
  if (!loading) return null;

  const fixedColor = '#F37335'; 

  return (
    <div className={`b-loader ${className} ${backdrop ? 'backdrop' : ''}`} {...rest}>
      <GridLoader color={fixedColor} loading={loading} size={size} />
    </div>
  );
}


Loader.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
};

Loader.defaultProps = {
  className: '',
  loading: false,
};

export default Loader;
