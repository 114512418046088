import React from "react";

import Main from '../../layout/main';
import values from "../../assets/images/school-values.jpg";

import './styles.scss'

const Values = () => {
    return (
        <Main className="b-values-wrapper">
            <div className="content b-width-wrapper ">


                <div className="data">
                    <div className="left">
                        <h2>Our <b> Values </b> </h2>
                        <strong>
                            Our values and attributes, which align with our guiding statements, are integral to creating a school culture and climate to realize our educational goals.
                        </strong>
                        <ul>
                            <li>
                                Cleanliness
                            </li>
                            <li>
                                Compassion
                            </li>
                            <li>
                                Commitment
                            </li>
                            <li>
                                Determination
                            </li>
                            <li>
                                Honesty
                            </li>
                            <li>
                                Perseverance
                            </li>
                            <li>
                                Respect
                            </li>
                            <li>
                                Responsibility
                            </li>
                            <li>
                                Self-Discipline
                            </li>
                            <li>
                                Teamwork
                            </li>

                        </ul>
                    </div>
                    <div className="image">
                        <img src={values} alt="values" />
                    </div>
                </div>

            </div>
        </Main>
    );
}

export default Values;