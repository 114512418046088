import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getRequest } from "../../utils/axios";
import Main from '../../layout/main';
import "./styles.scss";

const imageUrl = "https://dynamicproductstorage.blob.core.windows.net/gallery";

const EventDetail = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { pageUrl, categoryTypeName } = location.state || {};

    const [event, setEvent] = useState(null);
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchEventData = async () => {
            try {

                const eventResponse = await getRequest(`Page/GetPageByUrl/${categoryTypeName}/${pageUrl}`);
                debugger;
                const eventData = eventResponse.data;
                if (eventData) {
                    setEvent(eventData);
                    fetchGalleryData(eventData.pageId);
                } else {
                    console.error("No event data found for the provided URL.");
                    setError("Event not found");
                    navigate('/');
                }
            } catch (error) {
                console.error("Error fetching event data:", error);
                setError("Failed to load event details");
                navigate('/');
            } finally {
                setLoading(false);
            }
        };

        const fetchGalleryData = async (pageId) => {
            try {
                const galleryResponse = await getRequest(`PageGallery/GetPageGallery/${pageId}`);
                setImages(galleryResponse.data || []);
            } catch (error) {
                console.error("Error fetching gallery images:", error);
                setError("Failed to load gallery images");
            }
        };

        if (pageUrl && categoryTypeName) {
            fetchEventData();
        } else {
            setError("Invalid event details");
            navigate('/');
        }
    }, [pageUrl, categoryTypeName, navigate]);

    return (
        <Main className="b-event-detail-wrapper">
            <div className="content b-width-wrapper">
                {loading ? (
                    <p>Loading event details...</p>
                ) : error ? (
                    <p>{error}</p>
                ) : event ? (
                    <>
                        <h1>{event.pageName}</h1>
                        <p>{event.shortDescription}</p>
                        <div className="images">
                            {images.length > 0 ? (
                                images.map((img, index) => (
                                    <img
                                        key={index}
                                        src={`${imageUrl}/${img.galleryFilePath.replace(/\\/g, '/')}${img.galleryFileName}`}
                                        alt={img.galleryFileTitle || `Gallery image ${index}`}
                                    />
                                ))
                            ) : (
                                <p>No images found.</p>
                            )}
                        </div>
                    </>
                ) : (
                    <p>No event data available.</p>
                )}
            </div>
        </Main>
    );
}

export default EventDetail;
