import React from "react";

import Main from '../../layout/main';
import DeepakSir from "../../assets/images/Deepak-sir.jpeg";

import "./styles.scss";

const Tribute = () => {
    return (
        <Main>
            <div className="b-tribute-wrapper">
                <div className="b-width-wrapper">
                    <div className="b-tribute-content">
                        <h1> Tribute to Mr. Deepak Goyal: <span>  A Visionary Leader </span></h1>
                        <p>
                            Mr. Deepak Goyal, a stalwart in education and co-founder of The Indian Heights International School, has left behind a legacy marked by excellence and devotion.
                            Guiding our institution, his philosophy remains focused on building young minds.
                        </p>
                        <p>
                            As an innovative leader, Mr. Goyal was instrumental in establishing a school that not only teaches but also emphasizes strong moral values like honesty, kindness, and bravery.
                            Countless success stories have been realized at our school due to his unwavering commitment to quality education.
                        </p>
                        <p>
                            He encouraged holistic development in the school through his innovative approach to teaching, which involved instilling students’ potential within them.
                            Such an attitude led him to create a nurturing environment where all children can be successful academically, emotionally, and socially.
                        </p>
                        <p>
                            We pay tribute to Mr. Goyal,  honor his legacy by upholding the values he cherished, and continue to strive for excellence in education.
                            His impact on the lives of students, teachers, and parents alike will forever be remembered and cherished.
                        </p>
                        <p>
                            Thank you, Mr. Deepak Goyal, for your unwavering dedication, passion, and vision.
                            Your influence will continue to shape the future of generations to come.
                        </p>
                    </div>
                    <div className="image">
                        <img src={DeepakSir} alt="Deepak Sir" />
                    </div>
                </div>
            </div>
        </Main>
    );
}

export default Tribute;