import React, { Fragment } from "react";
import { useLocation } from "react-router-dom"; 

import HomeHeader from "../../components/organs/home-header";
import Header from "../../components/organs/header";
import Footer from "../../components/organs/footer"; 

import "./styles.scss";

const MainLayout = ({ className, children }) => {
    const location = useLocation(); 
    const isHomePage = location.pathname === '/'; 

    return (
        <Fragment>
            {isHomePage ? <HomeHeader /> : <Header />}
            <div className={`mainLayout ${className}`}>
                {children}
            </div>
            <Footer />
        </Fragment>
    );
}

export default MainLayout;
