import React from "react";

import Main from '../../layout/main';

import './styles.scss'

const Refund = () => {
    return (
        <Main className="b-refund-wrapper">
            <div className="content b-width-wrapper ">

                <h2> Refund Policy </h2>

                <div className="data">

                    <h3> Refund of Tuition Fee </h3>

                    <ol>
                        <li>
                            <strong> Application of refund before the commencement of the classes but not after the closure of admission for concerned programme: </strong>
                            In case an applicant withdraws before the commencement of the classes but not after the closure of admission for concerned programme and if the seat consequently falling vacant is filled by a waitlisted candidate (if any) then the Tuition Fee already deposited by the applicant shall be refunded after the deduction of processing fee Rs. 2,000/- (Two thousand only).
                        </li>
                        <li>
                            <strong> Application of refund after the commencement of the classes but not after the closure of admission for concerned programme: </strong>
                            In case an applicant withdraws after the commencement of the classes but not after the closure of admission for concerned programme and if the seat consequently falling vacant is filled by a waitlisted candidate (if any) then the Tuition Fee already deposited by the applicant shall be refunded after the deduction of processing fee Rs. 2,000/- (Two thousand only) and the proportionate monthly tuition fee.
                        </li>
                        <li>
                            Application of refund after the closure of admission for concerned programme, no tuition fee shall be refunded.
                        </li>
                    </ol>

                    <h3>
                        Non - Refundable Fee
                    </h3>

                    <ol>
                        <li>
                            Amount paid for obtaining Prospectus, Application fee, Processing fee, Entrance fee, late fee etc. is non-refundable under all circumstances, unless in specific are prescribed as refundable.
                        </li>
                        <li>
                            In case a student leaves after the last date of admission or withdraws/leaves in the midstream, in such cases, that student shall have no claim of refund of fee and other charges already paid.
                        </li>
                        <li>
                            Application of refund after the closure of admission for concerned programme, no tuition fee shall be refunded.
                        </li>
                        <li>
                            Respect
                        </li>
                    </ol>

                    <h3>
                        Procedure for Refund
                    </h3>

                    <ol>
                        <li>
                            For refund, applicant needs to apply to the School on the prescribed form as available in Registrar’s Office/Admission Office at the School Campus.
                        </li>
                        <li>
                            Only original form duly filled and signed by the student and parent/guardian received in person by the last date of admission will be accepted.
                        </li>
                        <li>
                            Application sent via email or any other mode will not be accepted and cannot be used as a reference for claim of refund.
                        </li>
                        <li>
                            Postal delay will not be the liability of the School and the date on which application by post is received by the School will be considered for refund.
                        </li>
                        <li>
                            Refund will be made only after the applicant has surrendered ID Card (if already issued) and other accessories issued by school at the time of refund.
                        </li>
                        <li>
                            Refund, if any, will be made through the account payee cheque only in the favour of concerned student or parent or guardian as opted and written by the student while filling the ‘Application Form for Refund’.
                            Once the application for refund has been submitted, then any request to change the name of payee (student/parent/guardian) or for request to reissue the cheque because of postal delay or otherwise.
                        </li>
                        <li>
                            Any amount, if refunded, will be in Indian currency only.
                        </li>

                    </ol>

                    <strong>
                        ** No interest is payable on any amount deposited with School also including refundable amount, if any.
                    </strong>

                </div>

            </div>
        </Main>
    );
}

export default Refund;