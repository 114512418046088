import React from "react";

import Main from '../../layout/main';

import './styles.scss'

const Privacy = () => {
    return (
        <Main className="b-privacy-wrapper">
            <div className="content b-width-wrapper ">

                <h2> Privacy Policy </h2>

                <div className="data">

                    <p>
                        This Privacy Policy describes the School’s agreement with you regarding how we will handle certain information on the Website.
                        This Privacy Policy does not address information obtained from other sources such as submissions by mail, phone or other devices or from personal contact.
                        By accessing the Website and/or providing information to the School on the Website, you consent to the collection, use and disclosure of certain information in accordance with this Privacy Policy.
                    </p>

                    <h3>
                        Information Collected on the Website
                    </h3>

                    <p>
                        If you merely download material or browse through the Website, our servers may automatically collect certain information from you which may include:
                    </p>
                    <ul>
                        <li>
                            The name of the domain and host from which you access the Internet.
                        </li>
                        <li>
                            The browser software you use and your operating system.
                        </li>
                        <li>
                            The Internet address of the website from which you linked to the Website.
                            The information we automatically collect may be used to improve the Website to make it as useful as possible for our visitors; however, such information will not be tied to the personal information you choose to provide to us.
                        </li>
                    </ul>

                    <p>
                        We do collect and keep personally identifiable information when you choose to voluntarily submit such information.
                        For example, if you choose to fill out a form on the Website we retain the information submitted by you.
                        You should not submit any information that you do not want to be retained.
                        After we have taken the appropriate action in response to your submittal, we retain the information you submit for our records and to contact you from time to time.
                        Please note that if we decide to change the manner in which we use or retain personal information, we may update this Privacy Policy, at our sole discretion.
                    </p>

                    <h3>
                        Disclosure of Personal Information of Third Parties
                    </h3>

                    <p>
                        The School does not rent or sell personal information that you choose to provide to us nor does the School disclose credit card or other personal financial information to third parties other than as necessary to complete a credit card or other financial transaction or as required by law.
                        The School does engage certain third parties to perform functions and provide services, including, without limitation, hosting and maintenance, customer relationship, database storage and management, payment transaction and direct marketing campaigns.
                        We will share your personal information with these third parties, but only to the extent necessary to perform the functions and provide the services, and only pursuant to binding contractual obligations requiring such third parties to maintain the privacy and security of your data.
                    </p>

                    <h3>
                        Receiving Promotional Materials
                    </h3>

                    <p>
                        We may send you information or materials such as newsletters, ebooks, whitepapers by e-mail or postal mail when you submit addresses via the Website.
                        By your using the Website, you are consenting to our sending you such information or materials.
                    </p>
                    <p>
                        If you do not want to receive promotional information or material, please send an email with your name, mailing address and email address to communications at
                        <a className="email" href="mailto:info@theindianheights.com.">info@theindianheights.com.</a>
                        When we receive your request, we may take reasonable steps to remove your name from such lists.
                    </p>

                    <h3>
                        Cookies
                    </h3>

                    <p>
                        A cookie is a small text file that a website can place on your computer's hard drive for record-keeping or other administrative purposes.
                        Our Website may use cookies to help to personalize your experience on the Website.
                        Although most web browsers accept cookies automatically, usually you can modify your browser setting to decline cookies.
                        If you decide to decline cookies, you may not be able to fully use the features of the Website.
                        Cookies may also be used at certain sites accessible through links on the Website.
                    </p>

                    <h3>
                        Links to other Websites
                    </h3>

                    <p>
                        The School is not responsible for the practices or policies of the websites linked to or from the Website, including without limitation their privacy practices or policies.
                        If you elect to use a link that accesses another party’s website, you will be subject to that website’s practices and policies.
                    </p>

                    <h3>
                        Google Analytics
                    </h3>

                    <p>
                        Some sections of our website use Google Analytics, a web analytics service provided by Google, Inc. Google Analytics uses cookies to help us analyze how people use our site.
                        The information generated by the cookie about your use of the website includes your Internet network address.
                        This information will be transmitted to and stored by Google on its servers.
                        Google will use this information for the purpose of evaluating your use of the website, compiling reports on website activity for our website administrators, and providing other services relating to website activity and Internet usage.
                        Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google’s behalf.
                        Google will not associate your network address with any other data held by Google.
                        To disable Google Analytics’ tracking of your usage of our site, you may disable cookies in your browser; however, please note that by doing so you may not be able to use the full functionality of this website.
                        By using this website, you consent to the processing of data about you by Google in the manner and for the purposes set out above.
                        To learn more, visit the Google Analytics Privacy Overview and Google’s privacy policy.
                    </p>

                </div>

            </div>
        </Main>
    );
}

export default Privacy;