import React from "react";

import Main from '../../layout/main';
import curriculum from "../../assets/images/curriculum (1).jpg";

import './styles.scss'

const Curriculum = () => {
    return (
        <Main className="b-curriculum-wrapper">
            <div className="content b-width-wrapper ">

                <h2>
                    Curriculum to nurture the
                    <b className="b-1"> Body, </b>
                    <b className="b-2"> Mind </b>
                    and
                    <b className="b-3"> Spirit </b>
                </h2>

                <div className="data">
                    <div className="left">
                        <h3>Comprehensive Curriculum </h3>
                        <p>
                            <b> TIHIS </b> curriculum supports the natural development of the child.
                            The teacher will serve less as an instructor and more as a guide or facilitator.
                            Children are encouraged to learn how to learn, thus gaining independence and self – confidence.
                        </p>

                    </div>
                    <div className="image">
                        <img src={curriculum} alt="values" />
                    </div>
                </div>

                <div className="section-2">
                    <div className="left">

                    </div>
                    <h3>
                        Outstanding Faculty that keeps Evolving
                    </h3>
                    <p>
                        Even though the fundamentals on how to teach are shifting and changing with the developments of technology and media, one thing never changes - The impact a teacher can have on a child’s life.
                        Teachers at TIHIS are not only committed to effective classroom teaching but they go beyond and become guides and mentors to students.
                        It is our endeavour to foster independent thinking, exploration and experimentation as a lifelong learning process.
                        We wish to develop in each, mastery over skills of communication, ability to think clearly, logically and independently and understand and appreciate our rich culture, ideas and practices.
                        We believe in providing the platform which challenges the young minds and instills in them a pride and glory to strive towards attaining the unknown yet fulfilling journey called life.
                    </p>

                    <h3>
                        Spacious and Facilities
                    </h3>
                    <p>
                        Children need space to spread their wings.
                        The designers and planners of The IHI have kept that in mind throughout.
                        Large airy classrooms, varied activity zones, ample areas for sports, and administrative services block functions as a dynamic, connected whole which is linked by wide corridors and courtyards.
                        Every day your child can look forward to the upcoming well–equipped Resource Centre with its well stocked library, audio–visual room, multi media centre and laboratories for building skills in computers, maths, science, languages, etc.
                    </p>

                </div>

                <h2>
                    A Proven Curriculum
                </h2>

                <p>
                    TIHIS follows the syllabus as prescribed by the CBSE.
                    It will be our constant endeavour to direct each child to become a motivated lifelong learner by experiencing the joy of discovery and the ownership of results.
                    Our curriculum has been structured to ensure that knowledge is imparted through a combination of resources and techniques, thus making the learning process exciting and easy to comprehend.
                    Classroom teaching, multi – media workshops, exchange programmes, co–curricular activities and excursions help children gain insight and knowledge at various levels.
                </p>

                <h2>
                    A World of Oppertunity beyond Academia
                </h2>

                <p>
                    A healthy body and mind that can appreciate nuances of aesthetics in nature and diverse human activity is the sine qua non for becoming a balanced human being.
                    To facilitate this, the school's calendar contains a host of co-curricular activities that cover a very wide spectrum. Performing and visual arts, sports and games, environmental studies, meditation and yoga will be practiced as a part of the routine.
                    Children are exposed to real world experience through interactions with the community, trips and excursions to places of historical and social importance and by getting a chance to interact with experts and resource persons from various fields of human endeavour.
                </p>

                <p>
                    At <b> TIHIS </b>, we encourage each and every TIHISites to participate in cultural, literary and social activities on and off campus.
                    Activity periods built into curriculum open avenues for exploration and pursuit of hobbies.
                    Our comprehensive sports arena encourages participation in various outdoor activities fostering team spirit, loyalty and unity - the essential components of the learning process.
                </p>

            </div>
        </Main>
    );
}

export default Curriculum;